<template>
    <div class=cardx>
        <b-card title="FAQ">
            <ul>
                <li>
                    <span v-b-toggle.collapse1>現在、定期課金で契約していて、プランをアップグレードしたい</span>
                    <b-collapse id="collapse1">→ 一旦現在のプランを解約後、新規に上位プランのご契約をお願いします。</b-collapse>
                </li>
                <li>
                    <span v-b-toggle.collapse2>定期課金に変更したい</span>
                    <b-collapse id="collapse2">→ 申し訳ございませんが、ご利用中のライセンスキーからの乗り継ぎはできません。新規に定期契約をお願いします。</b-collapse>
                </li>
                <li>
                    <span v-b-toggle.collapse3>定期課金から、都度、必要なタイミングで購入・更新に変更したい</span>
                    <b-collapse id="collapse3">→ 現在の定期契約を解約し、新規に購入手続きをお願いします。</b-collapse>
                </li>
                <li>
                    <span v-b-toggle.collapse4>法人ライセンスで一部のライセンスをアップグレードしたい</span>
                    <b-collapse id="collapse4">→ ライセンスキーを２つに分割いたします。<a :href="contact_url">問い合わせページ</a>よりご連絡ください。</b-collapse>
                </li>
                <li>
                    <span v-b-toggle.collapse5>法人ライセンスで更新時にライセンス数を変更したい</span>
                    <b-collapse id="collapse5">→ ライセンス数の追加・削減とともに、更新を希望される場合、<a :href="contact_url">問い合わせページ</a>よりご連絡ください。</b-collapse>
                </li>
                <li>
                    <span v-b-toggle.collapse6>CE版 (CD)を購入したい</span>
                    <b-collapse id="collapse6">→ 通常のライセンスで、完全にオフラインでの運用が可能（インターネット接続をすることなくライセンス認証が可能）となっておりますので、CDでの提供は廃止いたしました。30ライセンス以上(Enterprise版)でのご利用の場合に限り、対応が可能です（要別途費用）。</b-collapse>
                </li>
            </ul>

        </b-card>
    </div>
</template>

<script>
export default {
  data (){
    return {
      contact_url : process.env.VUE_APP_CONTACT_URL
    }
  }
}
</script>


<style scoped>
ul, ol {
  padding: 0;
  position: relative;
}

ul li, ol li {
  color: #2d8fdd;
  border-left: solid 6px #2d8fdd;/*左側の線*/
  background: #f1f8ff;/*背景色*/
  margin-bottom: 3px;/*下のバーとの余白*/
  line-height: 1.5;
  padding: 0.5em;
  list-style-type: none!important;/*ポチ消す*/
  display:block;
}
</style>
