<template>
    <div class=cardx>
        <b-card title="現時点でライセンス認証されているPC一覧">
            <b-list-group>
                <b-table striped hover :items="items" :fields="fields"></b-table>
            </b-list-group>
            
            <p style="font-size:12px;">
                ※ライセンス認証を解除する方法：FUGOの利用をとりやめるPCで、FUGOを起動し、画面上部の「ツール」→「ライセンスの解除」をクリックしてください。（要インターネット接続）<br>
                ※ライセンスの解除をせずにPCを破棄してしまった場合など、解除ができない場合には、<a :href="contact_url">問い合わせフォーム</a>より「FUGOライセンスの解除依頼」をお送りください。
            </p>
        </b-card>
    </div>
</template>

<script>
    export default {
        data () {
            return{
                fields: [
                    {key: 'pc_name', label: "PC Name"},
                    {key: 'rc_ip_id', label: "ID"}
                    
                ],
                contact_url : process.env.VUE_APP_CONTACT_URL
            }
        },
        props: {
            items: Array
        }
  }
</script>

<style scoped>
div.cardx { margin-top:20px;}
</style>
