<template>
    <div class=b>
        <ul class="btns">
            <order-button
            v-for="item in buttons"
            v-bind:text="item.text"
            v-bind:url="item.url"
            :key="item.id"></order-button>
        </ul>
    </div>
</template>

<script>
import OrderButton from './OrderButton.vue'

export default {
    data () {
        return{
        }
    },
    props: {
        buttons:Array
    },
    components: {
     OrderButton
  },
}
</script>

<style scoped>
div.b { display: inline;}
ul.btns { display: inline;}
</style>
