<template>
  <div>
    <b-card>
      <b-form @submit="onSubmit">
        <!--<b-form-group id="exampleInputGroup1"
                      label="メールアドレス："
                      label-for="exampleInput1">
          <b-form-input id="exampleInput1"
                        type="email"
                        v-model="info.email"
                        required
                        placeholder="Enter email">
          </b-form-input>
        </b-form-group>-->
        <b-form-group id="exampleInputGroup2"
                      label="ライセンスキー："
                      label-for="exampleInput2">
          <b-form-input id="exampleInput2"
                        type="text"
                        v-model="info.key"
                        aria-describedby="inputLiveFeedback"
                        :state="state"
                        required
                        placeholder="ライセンスキーを入力してください">
          </b-form-input>
          <b-form-invalid-feedback id="inputLiveFeedback">
            入力されたライセンスキーは存在しません
          </b-form-invalid-feedback>
        </b-form-group>
        <b-button type="submit" variant="primary">ライセンス情報の確認</b-button>
      </b-form>
    </b-card>   
  </div>
</template>

<script>
  var axios = require('axios')

  export default {
    data () {
        return{
            is_valid_key: null,
            info:{
                email: '',
                key: ''
            }
        }
    },
    computed:{
      state(){return this.is_valid_key}
    },
    props: {
    },
    methods:{
      //ライセンス情報の取得
      onSubmit : function(evt){
        evt.preventDefault();
        var vm = this
        var license_api_url =  process.env.VUE_APP_LICENSE_API_BASE + "/v4/licensekey" 
        var hash = "d27ed8ef0a434dbc5bd3bd2fa79281d9330a3d32a38918d46cf11a092b713519"
        axios.get(`${license_api_url}/${vm.info.key}/${hash}/`)
                .then(function(response){
                    vm.is_valid_key = null
                    //console.log(response.data)
                    vm.$emit('childs-event', response)
                })
                .catch(function(error){
                  vm.is_valid_key = false
                  console.log(error)
                  vm.$emit('clear-data-event')
                })
        }
    }
  }
</script>

<style scoped>

</style>
