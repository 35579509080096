<template>
  <li class=btn>
    <b-button :href="url" variant="primary" size="sm">{{ text }}</b-button>
  </li>
</template>

<script>
  module.exports = {
    props: {
      text: String,
      url: String
    }
  }
</script>

<style scoped>
.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}

li.btn {
  display: inline-block;
  margin: 0 10px;
}
</style>