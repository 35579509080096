<template>
    <div class=cardx>
        <b-card title="ライセンス情報">
            <b-list-group>
                <b-list-group-item><span class=label>製品名</span>：<b>{{ display_product_name }}</b></b-list-group-item>
                <b-list-group-item>
                    <span class=label>ライセンスタイプ</span>：<b>{{ license_type }}</b>
                    <order-button-list :buttons="subscription_buttons"></order-button-list>
                </b-list-group-item>
                <b-list-group-item>
                    <span class=label>プラン</span>：<b>{{ info.plan_name }} </b>
                    <order-button-list :buttons="upgrade_buttons"></order-button-list>
                </b-list-group-item>
                <b-list-group-item>
                    <span class=label>ライセンス期限</span>：<b>{{ info.expire_date }}</b>
                    <order-button-list :buttons="update_buttons"></order-button-list>
                    <div v-if="license_expired">
                        <b-alert show variant="danger">
                            ライセンスが失効している場合、更新手続きは行っていただけません。<br>
                            お手数をおかけいたしますが、新規でご購入手続きの上、ライセンスキーを新規発行して下さい。
                        </b-alert>
                    </div>
                </b-list-group-item>
                <b-list-group-item>
                    <span class=label>ライセンス数</span>：<b>{{ info.quantity }}</b> ( 残り認証可能台数：{{ info.rest_quantity }})
                    <order-button-list :buttons="add_buttons"></order-button-list>
                </b-list-group-item>
            </b-list-group>
            <p style="font-size:12px;">
                ※ライセンス数の追加・プランのアップグレードの費用についは、<a href="https://rc-ipworks.com/product/fugo/plan/">ライセンス購入に関するFAQ</a>を参照してください<br>
                ※期限の延長の際に、合わせてライセンス数の増減・プランの変更を希望される場合、<a :href='contact_url'>問い合わせページ</a>よりご連絡ください。
            </p>
        </b-card>
    </div>
</template>

<script>
    import OrderButtonList from './OrderButtonList.vue'

    export default {
        data () {
            return{
                contact_url : process.env.VUE_APP_CONTACT_URL
            }
        },
        props: {
            info: Object,
            subscription_buttons: Array,
            upgrade_buttons: Array,
            update_buttons: Array,
            add_buttons: Array,
            license_expired: Boolean,
            products_json: Object,
        },
        computed: {
            license_type : function(){
                if (typeof this.info.plan == 'undefined'){return ""}

                var type = "通常ライセンス"
                //if(this.info.customer_type == "corporate"){type = "法人ライセンス"}

                var subscription_mark = ""
                if(this.info.is_subscription){ subscription_mark = "(定期課金契約)" }
                else if( this.info.is_paypal_subscription){ subscription_mark = "(Paypal定期課金契約)"}
                
                return type + subscription_mark
            },
            display_product_name : function(){
                var name = this.info.product_name
                if( name == "fugo"){name = "FUGO"}
                return name
            }
        },
        components: {
            OrderButtonList,
        }
  }
</script>

<style scoped>
div.cardx { margin-top:20px;}
span.label {
    display: inline-block; 
    width:130px
    }
</style>
