<template>
  <div>
    <app-header>ライセンスの更新</app-header>

    <app-notice />

    <div class="alert alert-light">
      <ul>
        <li>更新手続きには、現在ご利用中の「ライセンスキー」と、ライセンス購入時に入力された「メールアドレス」（登録メールアドレス）が必要となります。</li>
        <li>登録メールアドレスの変更をご希望の場合は、<a :href='contact_url'>問い合わせページ</a>よりご連絡ください。</li>
        <li>ライセンス認証されたPCの一覧は、「ライセンスの確認」ページよりご確認いただけます。</li>
      </ul>
    </div>   

    <license-input @childs-event="set_info" @clear-data-event="clear_data"></license-input>

    <div v-if="response_confirmed">
      <license-info :info="info" 
      :upgrade_buttons="upgrade_buttons"
      :update_buttons="update_buttons"
      :add_buttons="add_buttons"
      :subscription_buttons="subscription_buttons"
      :products_json="products_json"
      :license_expired ="license_expired"> 
      </license-info>
      <br>
      <faq />
    </div>
  </div>
</template>

<script>
  import LicenseInput from '@/components/LicenseInput.vue'
  import LicenseInfo from '@/components/LicenseInfo.vue'
  import Faq from '@/components/Faq.vue'
  import axios from 'axios'
  import AppHeader from '@/components/AppHeader' 
  import AppNotice from '@/components/AppNotice' 

  export default {
    name: 'info_renew',
    data () {
      return {
        response_confirmed : false,
        license_expired : false,
        info:{},
        pc_list: [],
        subscription_buttons: [],
        upgrade_buttons: [],
        update_buttons: [],
        add_buttons: [],

        products_json: {},

        contact_url: process.env.VUE_APP_CONTACT_URL,
        license_url : process.env.VUE_APP_LICENSE_API_BASE,
        payment_url : process.env.VUE_APP_PAYMENT_API_BASE
      }
    },
    components: {
      LicenseInput,
      LicenseInfo,
      Faq,
      AppHeader,
      AppNotice
    },
    computed: {
    },
    methods:{
      //
      // データを消去
      clear_data: function(){
        this.response_confirmed = false

        var vm = this
        this.info = {}
        vm.get_price_json()
        vm.get_list()
      },
      //
      // データをセット
      set_info: function(response){
        this.response_confirmed = true

        var vm = this
        vm.info = response.data
        vm.info.plan_code = vm.info.plan
        vm.get_price_json()
      },
      //
      // 商品情報を取得する、利用可能なプランを作成する
      // ボタンを作る
      get_price_json: function(){
        var vm = this
        vm.subscription_buttons = []
        vm.upgrade_buttons =[]
        vm.update_buttons = []
        if(typeof vm.info == 'undefined'){return}

        var json_url = process.env.VUE_APP_PRODUCTS_JSON_URL
        axios.get(json_url)
            .then(function(response){
                //console.log(response)
                var upgrade_plans = []
                var plans = response.data[vm.info.product_name].plans
                vm.info.display_product_name = response.data[vm.info.product_name].display_product_name //adhoc

                Object.keys(plans).forEach(function(key) {
                  var plan = plans[key]
                  if (Number(plan.plan_code) > vm.info.plan_code){
                    upgrade_plans.push(plan)
                  } 
                }) 

                var now = new Date();
                var today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
                if(Date.parse(vm.info.expire_date) < today)
                {
                  vm.info.expire_date += " (ライセンス失効)"
                  vm.info.rest_quantity = 0
                  vm.license_expired = true
                  return
                }
                else
                {
                  vm.license_expired = false
                  if(vm.info.is_paypal_subscription || vm.info.is_subscription)
                  {
                    vm.create_subscription_buttons()
                  }
                  else
                  {
                    vm.create_update_buttons()
                    vm.create_upgrade_buttons(upgrade_plans)
                    vm.create_add_buttons()
                  }
                }
            })
            .catch(function(error){
              console.log(error)
            })
      },
      //
      // 定期購読解除ボタンの作成
      create_subscription_buttons: function(){
        var vm = this
        var b = []
        if(vm.info.is_subscription){
          var url = `${vm.payment_url}/${vm.info.product_name}/subscription/cancel`
          b.push( { text : "解約",url:url,id:991})
        }
        else if(vm.info.is_paypal_subscription){ 
          var url_paypal = process.env.VUE_APP_PAYPAL_UNSUBSCRIBE_URL //FUGO以外ではPayPal使わないので決め打ちでOK
          vm.info.expire_date = "弊社問い合わせフォームにお問い合わせください"
          b.push( { text : "解約",url:url_paypal ,id:992})
        }
        vm.subscription_buttons = b
      },
      //
      // プランアップグレードボタンの作成
      create_upgrade_buttons : function(available_plans){
          var vm = this
          var upgrade = []
          Object.keys(available_plans).forEach(function(key) {
            var plan = available_plans[key]
            var url = `${vm.payment_url}/${vm.info.product_name}/${plan.plan_name}/${vm.info.customer_type}/order/upgrade`
            upgrade.push({
                text : plan.display_plan_name + "にアップグレード",
                url : url,
                id : 1000 + Number(plan.plan_code)
                })
          })
          vm.upgrade_buttons = upgrade
      },
      //
      // 延長ボタンの作成
      create_update_buttons : function(){
        var vm = this
        var updates = []
        var url = `${vm.payment_url}/${vm.info.product_name}/${vm.info.plan_name.toLowerCase()}/${vm.info.customer_type}/order/update`
        updates.push( {
          text : "ライセンス期限を延長",
          url:url,
          id:10
          })
        vm.update_buttons = updates
      },
      //
      // 追加ボタンの作成
      create_add_buttons : function(){
        var vm = this
        var b = []
        if(vm.info.customer_type == "corporate"){
        var url = `${process.env.VUE_APP_PAYMENT_API_BASE}/${vm.info.product_name}/${vm.info.plan_name.toLowerCase()}/${vm.info.customer_type}/order/add`
        b.push( {
            text : "ライセンス数を追加",
            url:url,
            id:200
            })
        }
        vm.add_buttons = b
      }
    }
}
</script>

<style scoped>

h1, h2 {
  font-weight: normal;
}

a {
  color: #42b983;
}
</style>
